<template>
  <pro-menu-layout>
    <div class="q-pa-md">
      <div class="q-gutter-xs">
        <pro-deck-card :title="getRes('Form.Section.AwardCriteria')" expandable>
          <div class="q-pl-md q-gutter-y-sm">
            <div>{{ awardCriteriaStr }}</div>
            <div>
              {{ awardCriteria.criteriaRemarks }}
            </div>
          </div>
        </pro-deck-card>
        <pro-deck-card :title="getRes('Form.Section.TEC')" expandable>
          <div class="q-pl-md q-pb-md q-gutter-y-sm">
            <div class="row">
              <div class="col-2">
                {{ getRes("Form.Field.Stage") }} {{ stage }}
              </div>
              <div class="col">{{ evaluationCriteriaStr }}</div>
            </div>
            <div class="row items-center">
              <div class="col-2">{{ getRes("Form.Field.ST") }}</div>
              <div class="col">
                <q-btn
                  no-caps
                  dense
                  round
                  flat
                  color="primary"
                  icon="download"
                  @click="handleDownload(scoreSheetTemplateFileId)"
                />
              </div>
            </div>
          </div>
          <q-markup-table dense flat separator="none">
            <thead>
              <tr class="q-tr--no-hover">
                <th class="text-left">{{ getRes("Form.Col.Item") }}</th>
                <th class="text-left">{{ getRes("Form.Col.Description") }}</th>
                <th class="text-center">{{ getRes("Form.Col.Compliance") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="scoreSheetItem in technicalEvaluation.scoreSheet"
                :key="scoreSheetItem.item"
                class="q-tr--no-hover"
              >
                <td class="text-left">{{ scoreSheetItem.item }}</td>
                <td class="text-left">{{ scoreSheetItem.description }}</td>
                <td class="text-center">
                  {{ scoreSheetItem.weight ? scoreSheetItem.weight : "P/F" }}
                </td>
              </tr>
            </tbody>
          </q-markup-table>
        </pro-deck-card>
        <pro-deck-card :title="getRes('Form.Field.AG')" expandable>
          <div
            v-for="attachment in assessmentGuidelineFiles"
            :key="attachment.fileId"
            class="q-pl-md"
          >
            <pro-file :file="attachment" show-download-button />
          </div>
        </pro-deck-card>
      </div>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProFile from "@/components/PROSmart/ProFile";

export default {
  components: { ProMenuLayout, ProDeckCard, ProFile },
  data() {
    return {
      stage: "",
      awardCriteria: {},
      technicalEvaluation: {},
      scoreSheetTemplateFileId: "",
      assessmentGuidelineFiles: [],
    };
  },
  computed: {
    awardCriteriaStr() {
      switch (this.awardCriteria.criteria) {
        case "PriceOnly":
          return this.$t("Form.Option.Price-Only");
        case "TechnicalPass":
          return this.$t("Form.Option.100% Price subject to Technical Pass");
        case "Weighted":
          return `${this.$t("Form.Option.Weighted")} ${this.$t(
            "CustMenu.Col.Technical"
          )} ${this.awardCriteria.technicalWeight}% vs ${this.$t(
            "Form.Option.Weighted"
          )} ${this.$t("Form.Field.Price")} ${this.awardCriteria.priceWeight}%`;
        default:
          return this.awardCriteria.criteria;
      }
    },
    evaluationCriteriaStr() {
      switch (this.technicalEvaluation.passingCriteria) {
        case "TechnicalPassStrict":
          return "Technical Pass (Strict compliance)";
        case "TechnicalPassMajority":
          return "Technical Pass (Majority to determine)";
        case "PassingScore":
          return `Achieving the Passing Score ${this.technicalEvaluation.passingScore}%`;
        default:
          return this.technicalEvaluation.passingCriteria;
      }
    },
  },
  methods: {
    handleDownload(fileId) {
      this.$proSmart.download.downloadFile(
        this,
        this.$route.params.id,
        fileId,
        `Score Sheet Template ${this.LocalUser.EName}`
      );
    },
  },
  created() {
    this.$proSmart.tender
      .getAwardEvaluationCriteriaForAssessor(this, this.$route.params.id)
      .then((msg) => {
        this.stage = msg.stage;
        this.awardCriteria = msg.awardCriteria;
        this.technicalEvaluation = msg.technicalEvaluation;
        this.scoreSheetTemplateFileId = msg.scoreSheetTemplateFileId;
        this.assessmentGuidelineFiles = msg.assessmentGuidelineFiles;
      });
  },
};
</script>

<style lang="scss" scoped>
.q-table th {
  font-weight: bold;
  font-size: 13px;
}
</style>
